import {all, call, put, takeLatest, delay, fork } from 'redux-saga/effects';
import {
    allCourseAPI, bookmarkCourseAPI, categoriesAPI, courseDetailAPI, enrollCourseAPI, myCoursesAPI, quizAPI,
    quizSubmitAPI, ratingSubmitAPI,
    unBookmarkCourseAPI,
    unEnrollCourseAPI, watchCourseStatusAPI,evaluationAPI, evaluationSubmitAPI
} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    BOOKMARK_COURSE, BOOKMARKED_COURSE_SUCCESS, COMPLETED_COURSE_SUCCESS,
    COURSE_DETAIL, COURSE_DETAIL_FAILED, COURSE_DETAIL_SUCCESS, COURSE_WATCH_STATUS, ENROLL_COURSE,
    FETCH_CATEGORIES, FETCH_CATEGORIES_FAILED, FETCH_CATEGORIES_SUCCESS,
    FETCH_COURSE, FETCH_COURSE_FAILED, FETCH_COURSE_SUCCESS, FETCH_MYCOURSE, FETCH_QUIZ, FETCH_QUIZ_FAILED,
    FETCH_QUIZ_SUCCESS,
    HIDE_LOADING,
    INPROGRESS_COURSE_SUCCESS,
    SHOW_LOADING, SUBMIT_COURSE_RATING, SUBMIT_QUIZ, UNBOOKMARK_COURSE, UNENROLL_COURSE,FETCH_EVALUATION,SUBMIT_EVALUATION,FETCH_EVALUATION_SUCCESS
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";


function* fetchCourses(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(allCourseAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_COURSE_SUCCESS, courses: response.data.data.category});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_COURSE_FAILED});
    }
}

function* fetchCategories() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(categoriesAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_CATEGORIES_SUCCESS, categories: response.data.data});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_CATEGORIES_FAILED});
    }
}

function* fetchCourseDetail(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(courseDetailAPI, action.payload);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
        yield put({type: COURSE_DETAIL_SUCCESS, courseDetail: response.data.data});
    } catch (error) {
        errorMessage(error);
        console.log(error,'errrrrrrrrrrr')
        yield put({type: HIDE_LOADING});
        yield put({type: COURSE_DETAIL_FAILED});
    }
}

function* onEnroll(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(enrollCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* onUnEnroll(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(unEnrollCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* onBookmark(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(bookmarkCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* onUnBookmark(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(unBookmarkCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* fetchMyCourses(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(myCoursesAPI, action.payload);
        yield put({type: HIDE_LOADING});
        if(action.payload && action.payload.status === 'ENROLL') {
            console.log(response,'action.payload',action.payload.status)
            yield put({type: INPROGRESS_COURSE_SUCCESS, inProgressCourses: response.data && response.data.data});
        } else if(action.payload && action.payload.status === 'COMPLETED') {
            yield put({type: COMPLETED_COURSE_SUCCESS, completedCourses: response.data && response.data.data});
        } else if(action.payload && action.payload.status === 'BOOKMARKED') {
            yield put({type: BOOKMARKED_COURSE_SUCCESS, bookmarkedCourses: response.data && response.data.data});
        }
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        if(action.payload && action.payload.status === 'ENROLL') {
            yield put({type: INPROGRESS_COURSE_SUCCESS, inProgressCourses: []});
        } else if(action.payload && action.payload.status === 'COMPLETED') {
            yield put({type: COMPLETED_COURSE_SUCCESS, completedCourses: []});
        } else if(action.payload && action.payload.status === 'BOOKMARKED') {
            yield put({type: BOOKMARKED_COURSE_SUCCESS, bookmarkedCourses: []});
        }
    }
}

function* fetchQuiz(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(quizAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_QUIZ_SUCCESS, quizData: response.data.data});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_QUIZ_FAILED});
    }
}

function* submitQuiz(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(quizSubmitAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* submitRating(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(ratingSubmitAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}


function* fetchEvaluation(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(evaluationAPI, action.payload);
        yield put({type: HIDE_LOADING});
        // yield put({type: FETCH_EVALUATION_SUCCESS, evaluationData: response.data.data});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* submitEvaluation(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(evaluationSubmitAPI, action.payload);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}


function* watchCourseStatus(action) {
    try {
        yield call(watchCourseStatusAPI, action.payload);
    } catch (error) {

    }
}

function* actionCategories() {
    yield takeLatest(FETCH_CATEGORIES, fetchCategories)
}

function* actionAllCourse() {
    yield takeLatest(FETCH_COURSE, fetchCourses)
}

function* actionCourseDetail() {
    yield takeLatest(COURSE_DETAIL, fetchCourseDetail)
}

function* actionEnrollCourse() {
    yield takeLatest(ENROLL_COURSE, onEnroll)
}

function* actionUnEnrollCourse() {
    yield takeLatest(UNENROLL_COURSE, onUnEnroll)
}

function* actionBookmark() {
    yield takeLatest(BOOKMARK_COURSE, onBookmark)
}

function* actionUnBookmark() {
    yield takeLatest(UNBOOKMARK_COURSE, onUnBookmark)
}

function* actionMyCourses() {
    yield takeLatest(FETCH_MYCOURSE, fetchMyCourses)
}

function* actionWatchCourse() {
    yield takeLatest(COURSE_WATCH_STATUS, watchCourseStatus)
}

function* actionFetchQuiz() {
    yield takeLatest(FETCH_QUIZ, fetchQuiz)
}

function* actionSubmitQuiz() {
    yield takeLatest(SUBMIT_QUIZ, submitQuiz)
}

function* actionSubmitRating() {
    yield takeLatest(SUBMIT_COURSE_RATING, submitRating)
}


function* actionFetchEvaluation() {
    yield takeLatest(FETCH_EVALUATION, fetchEvaluation)
}

function* actionSubmitEvaluation() {
    yield takeLatest(SUBMIT_EVALUATION, submitEvaluation)
}


export default function* authSaga() {
    yield all([
        fork(actionCategories),
        fork(actionAllCourse),
        fork(actionCourseDetail),
        fork(actionEnrollCourse),
        fork(actionUnEnrollCourse),
        fork(actionBookmark),
        fork(actionMyCourses),
        fork(actionUnBookmark),
        fork(actionWatchCourse),
        fork(actionFetchQuiz),
        fork(actionSubmitQuiz),
        fork(actionSubmitRating),
        fork(actionFetchEvaluation),
        fork(actionSubmitEvaluation),
    ]);
}
