import React from "react";
import { CTooltip } from "@coreui/react";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/logo.png";
import { MESSAGES } from "../../constants/Messages";

const footer = () => {
  const redirectTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* <CCard className='FootWrap'>
            <CCardBody>
                <div className='FootHead'>
                    <h3><i className="fa fa-minus" aria-hidden="true"></i> <span>Mouris sed</span> blandit mi.Maecenas</h3>
                </div> */}
                {/* <CRow>
                    <CCol md="3" sm='6'>
                        <h3>Fermentum</h3>
                        <ul>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                        </ul>
                    </CCol>
                    <CCol md="3" sm='6'>
                        <h3>Fermentum</h3>
                        <ul>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                        </ul>
                    </CCol>
                    <CCol md="3" sm='6'>
                        <h3>Fermentum</h3>
                        <ul>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                        </ul>
                    </CCol>
                    <CCol md="3" sm='6'>
                        <h3>Fermentum</h3>
                        <ul>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                            <li><a href='#'>Lorem</a></li>
                        </ul>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard> */}
      <div className="FootBottom">
        <div className="FootBottomInner">
          <div  className='footer-socialmedia-section-desktop footer-logo-negative-margin'>
            <ul>
              <h3>
                <img src={LogoImage} alt="logo" />
              </h3>
              <li>
                <span>Phone : </span> (949) 833-8255
              </li>
              <li>
                <span>Email :</span>{" "}
                <a href="mailto:info@passymuir.com">info@passymuir.com</a>
              </li>
              {/* <li><span>Address</span> Test</li> */}
              <li className="socialWrap">
                <a href="https://www.instagram.com/passymuir/">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/passymuir/"> 
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCrJ_BmcqlCosF1heNCiLWKg">
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="https://twitter.com/PassyMuir">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="pl-0 pl-lg-4">
            <ul>
              <h3>Community</h3>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/membership-details">Membership</Link>
              </li>
              {/* <li><Link to="/">Sagittis</Link></li>
                            <li><Link to="/">Eget Quam</Link></li>
                            <li><Link to="/">Sed Aliquam</Link></li>
                            <li><Link to="/">Velit Ut</Link></li>
                            <li><Link to="/">Mauris Nunc Justo</Link></li>
                            <li><Link to="/">Sollicitudin </Link></li> */}
            </ul>
          </div>
          <div>
            <ul>
              <h3>Others link</h3>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/course-preview">Course Preview</Link>
              </li>
              {/* <li><Link to="/contact-us">Contact Us</Link></li> */}
              {/* <li><Link to="/membership-details">Membership</Link></li> */}
            </ul>
          </div>
          <div>
            {" "}
            <ul>
              <h3>Join Us</h3>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              {/* <li><Link to="/">Malesuada Fames</Link></li>
                        <li><Link to="/">Lorem Ipsum</Link></li>
                        <li><Link to="/">Volutpat Consequat</Link></li>
                        <li><Link to="/">Suspendisse Sollicitudin</Link></li> */}
            </ul>
          </div>

          <div  className='footer-socialmedia-section-mobile mt-4 mt-lg-0 footer-logo-negative-margin'>
            <ul>
              <h3>
                <img className="footer-log-tablet-view" src={LogoImage} alt="logo" />
              </h3>
              <li>
                <span>Phone : </span> (949) 833-8255
              </li>
              <li>
                <span>Email :</span>{" "}
                <a href="mailto:info@passymuir.com">info@passymuir.com</a>
              </li>
              {/* <li><span>Address</span> Test</li> */}
              <li className="socialWrap">
                <a href="https://www.instagram.com/passymuir/">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/passymuir/">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCrJ_BmcqlCosF1heNCiLWKg">
                  <i className="fa fa-youtube"></i>
                </a>
                <a href="https://twitter.com/PassyMuir">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className='footer-socialmedia-section-mobile'></div>
          <div  className='footer-socialmedia-btn-section-margin-top'>
            <ul>
              <h3>Download App</h3>
              <li>
                <CTooltip content={MESSAGES.CommingSoon} placement="top">
                  <button className="apple">
                    <i className="fa fa-apple" aria-hidden="true"></i> Apple IOs
                  </button>
                </CTooltip>
              </li>
              <li>
                <CTooltip content={MESSAGES.CommingSoon} placement="bottom">
                  <button className="gplay">
                    <i className="fa fa-android" aria-hidden="true"></i>Google
                    Play
                  </button>
                </CTooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Copywrite">
        <div className="InnerWrap">
          <div className="col-md-6 cl-sm-12">
            <p>Copyright © 2022 Medicorumslp. All Rights Reserved.</p>
          </div>
          <div className="col-md-6 cl-sm-12 text-right">
            <button onClick={redirectTop}>
              <span className="footer-top-bar-icon-text">Top Bar</span>{" "}
              <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default footer;
