import {FETCH_PAGE_FAILED, FETCH_PAGE_SUCCESS} from "../constants/ActionTypes";

const initialState = {
    sidebarShow: 'responsive',
    content: ''
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case 'set':
            return {...state, sidebarShow: action.sidebarShow }
        case 'SHOW_LOADING':
            return {...state, loading: true }
        case 'HIDE_LOADING':
            return {...state, loading: false }
        case FETCH_PAGE_SUCCESS:
            return {...state, content: action.payload}
        case FETCH_PAGE_FAILED:
            return {...state, content: ''}
        default:
            return state
    }
}