import {
    VIDEO_ITEM_SUCCESS, RESOURCE_ITEM_FAILED, VIDEO_RESOURCE_DETAIL,
    DOC_ITEM_SUCCESS, PPT_ITEM_SUCCESS, IMAGE_ITEM_SUCCESS,DOC_ITEM_DETAIL,IMAGE_RESOURCE_DETAIL,PPT_RESOURCE_DETAIL
} from "../constants/ActionTypes";

const initialState = {
    videoResource: {},
    docResource: {},
    PPTResource: {},
    imageResource: {},
    videoDetail: {},
    imageDetail: {},
    docDetail: {},
    PPTDetail: {},
    docDetail: {},
    pptDetail: {}

};

export default function resource(state = initialState, action) {
    switch (action.type) {
        case VIDEO_ITEM_SUCCESS:
            return {...state, videoResource: action.videoResource }
        case DOC_ITEM_SUCCESS:
            return {...state, docResource: action.docResource }
        case PPT_ITEM_SUCCESS:
            return {...state, PPTResource: action.PPTResource }
        case IMAGE_ITEM_SUCCESS:
            return {...state, imageResource: action.imageResource }
        case RESOURCE_ITEM_FAILED:
            return {...state, initialState }
        case VIDEO_RESOURCE_DETAIL:
            return {...state, videoDetail: action.payload }
        case DOC_ITEM_DETAIL:
            return {...state, docDetail: action.docDetail }
        case IMAGE_RESOURCE_DETAIL:
           return {...state, imageDetail: action.imgDetail }
        case PPT_RESOURCE_DETAIL:
           return {...state, pptDetail: action.pptDetail }
            
        default:
            return state
    }
}