import {
    FETCH_MASTERS_FAILED, FETCH_MASTERS_SUCCESS, FETCH_PLANS_FAILED, FETCH_PLANS_SUCCESS, LOGIN_FAILED, LOGIN_SUCCESS,
    LOGOUT_SUCCESS, USER_PROFILE_FAILED, USER_PROFILE_SUCCESS,FETCH_APPCONTENT_SUCCESS,FETCH_APPCONTENT_FAILED
} from "../constants/ActionTypes";

const initMasterData = {
    facilities: [],
    affiliation: [],
    keywords: [],
    membership: [],
    appcontent: []
}
export default function auth(state = {userData: {}, masterData: initMasterData, planData: [], stateData: [], timeZoneData: [], userProfile: {}}, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state, userData: action.userData};
        case LOGIN_FAILED:
            return {...state, userData: {}};
        case LOGOUT_SUCCESS:
            return {...state, userData: {}};
        case FETCH_MASTERS_SUCCESS:
            return {...state, masterData: action.masterData, stateData: action.stateData, timeZoneData: action.timeZoneData};
        case FETCH_MASTERS_FAILED:
            return {...state, masterData: initMasterData, stateData: [], timeZoneData: []};
        case FETCH_PLANS_SUCCESS:
            return {...state, planData: action.planData, stateData: action.stateData};
        case FETCH_PLANS_FAILED:
            return {...state, planData: [], stateData: []};
        case USER_PROFILE_SUCCESS:
            return {...state, userProfile: action.userProfile};
        case USER_PROFILE_FAILED:
            return {...state, userProfile: {}};
        case FETCH_APPCONTENT_SUCCESS:
            return {...state, appcontent: action.appContentData};
        case FETCH_APPCONTENT_FAILED:
            return {...state, appcontent: []};
        default:
            return state
    }
}