import {all, call, fork, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {paymentInformationAPI, myCEUsAPI, submitCEUsAPI,} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    FETCH_PAYMENT_INFORMATION, PAYMENT_INFORMATION_FAILED, PAYMENT_INFORMATION_SUCCESS,
     HIDE_LOADING, SHOW_LOADING, BILLING_INFORMATION_SUCCESS
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";


function* fetchPaymentInfo(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(paymentInformationAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: PAYMENT_INFORMATION_SUCCESS, paymentInformationData: response.data.data.biill_to});
        yield put({type: BILLING_INFORMATION_SUCCESS, billingInformationData: response.data.data.credit_card});
        // action.onSuccess(response)
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: PAYMENT_INFORMATION_FAILED});
    }
}


function* actionPaymentInfo() {
    yield takeEvery(FETCH_PAYMENT_INFORMATION, fetchPaymentInfo)
}


export default function* paymentSaga() {
    yield all([
        fork(actionPaymentInfo),
    ]);
}
