import {
    PAYMENT_INFORMATION_FAILED, PAYMENT_INFORMATION_SUCCESS, BILLING_INFORMATION_SUCCESS
} from "../constants/ActionTypes";
const initialState = {
    paymentInformationData: {},
    billingInformationData: {}
};

export default function payment(state = {paymentInformationData: {}, billingInformationData: {} }, action) {
    switch (action.type) {
        case PAYMENT_INFORMATION_SUCCESS:
            return { ...state, paymentInformationData: action.paymentInformationData }
        case BILLING_INFORMATION_SUCCESS:
            return { ...state, billingInformationData: action.billingInformationData }
        case PAYMENT_INFORMATION_FAILED:
            return { ...state, paymentInformationData: {}, billingInformationData: {} }
        default:
            return state
    }
}