import React from 'react'
import {isLoggedIn} from "../../helpers/utils";
import BannerImage from "../../assets/banner.png"
import {Link} from "react-router-dom";

  const Banner = (props) => {
      
    return (
        <>
        
            {/* <div className='BannerWrap BannerLayout'> */}
            {
            isLoggedIn() 
            
            ?
            
             <div className='BannerWrap BannerLayout' style={{backgroundImage: `url('${BannerImage}')`}}>
                <div className='HeadingWrap'>
                    <h1>"When You Have Really Solid Biology And Medical Science At The Core Of An Issue, It Makes  It Much Easier To Identify What Potential Solutions May Be"<span>James Redford</span></h1>
                </div>
            </div>
            :
                <div className='BannerWrap' style={{backgroundImage: `url('${BannerImage}')`}}>
                    <div className='HeadingWrap'>
                        {props.mainTitle}
                        <p className='breadcrumb'><Link to='/'>Home</Link> <i className="fa fa-chevron-right" aria-hidden="true"></i> {props.pageName}</p>
                    </div>
                </div>
               
            }
           
        </>
    )
  }

Banner.defaultProps = {
    mainTitle: '',
    pageName: '',
}

  export default Banner