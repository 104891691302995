import React, {useEffect, useRef,useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify';
import {useForm} from "react-hook-form";
import {CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CFormGroup, CLabel, CRow, CFormText, CTooltip} from '@coreui/react'
import {isLoggedIn, setMaxLength, setMinLength} from "../../../helpers/utils";
import { onResendpasswordLink} from "../../../actions/AuthAction";
import { useHistory } from "react-router-dom";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../../constants/Constants";
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Banner from '../../../components/banner'
import SignInImage from "../../../assets/signupimage.png"
const ResendVerificationLink = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [emailid, setEmailid] = useState('');

    useEffect(()=>{
         
    }, []);
    const onSuccess = (responseData) => {
        history.push('/login');
    };
   
    const onSubmit = (data) => {
        dispatch(onResendpasswordLink(data, onSuccess));
    };

    if(isLoggedIn()){
        return <Redirect to={'/my-courses/enrol'} />
    }

    return (
        <>
        <Header/>
        <Banner mainTitle={[<h1 key="main">Verify Your Account</h1>]} pageName={'Verify Account'}/>
        <div className="LoginWrap">
            <div className='InnerWrap'>
                <CRow>
                    <CCol md="5">
                        <CCardGroup>
                            <CCard>
                                <CCardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h1>Verify Your Account</h1>
                                        <CFormGroup>
                                            <CLabel htmlFor="nf-email">Email <span style={{ color: 'red' }}>* </span></CLabel>
                                            <input  placeholder={'Enter Email'} className={'form-control'} {...register("email", { required: true, pattern:{value:EMAIL_REGEX, message: 'Please enter a valid email address' }})} />
                                            {errors.email?.type === 'required' && <CFormText className="help-block">Please enter your email</CFormText>}
                                            {errors.email?.type === 'pattern' && <CFormText className="help-block">Please enter a valid email address</CFormText>}
                                        </CFormGroup>

                                        <div className='ButtonWrap'>
                                            <CButton type={'submit'} color="primary" className="Login">Submit</CButton>
                                            <CButton color="primary" className="Register" active tabIndex={-1}><Link to="/login">
                                            Back to Login</Link></CButton>
                                        </div>

                                    </form>
                                </CCardBody>
                            </CCard>

                        </CCardGroup>
                    </CCol>
                    <CCol md="7">
                        <CCard className="ImageWrapSignIn">
                            <CCardBody>
                                <img src={SignInImage} alt='signin'/>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>

        </div>
        <Footer/>
        </>
    )
}

export default ResendVerificationLink
