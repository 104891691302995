import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {submitCUsAPI, submitEndCUsAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    MY_CEUS_FAILED,
    MY_CEUS_SUCCESS,
    SHOW_LOADING,
    SUBMIT_CUS, SUBMIT_END_CUS
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";

function* submitContactus(action) {
    // alert('1')
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(submitCUsAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}


function* actionSubmitCUs() {
    yield takeLatest(SUBMIT_CUS, submitContactus)
}

function* submitEndContactus(action) {
    // alert('1')
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(submitEndCUsAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}


function* actionSubmitEndCUs() {
    yield takeLatest(SUBMIT_END_CUS, submitEndContactus)
}

export default function* authSaga() {
    yield all([
        fork(actionSubmitCUs),
        fork(actionSubmitEndCUs),
    ]);
}
