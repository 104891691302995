import { combineReducers } from 'redux'
import news from './NewsReducer'
import counter from './CounterReducer'
import common from './CommonReducer'
import auth from './AuthReducer'
import course from './CourseReducer'
import ceus from './CEUsReducer'
import lending from './LendingReducer'
import resource from './ResourceReducer'
import events from './EventReducer'
import reffaqs from './FAQReducer'
import payment from './PaymentReducer'
import notification from './NotificationReducer'
import referral from './ReferralReducer'

export default combineReducers({
    auth,
    news,
    counter,
    common,
    course,
    ceus,
    lending,
    resource,
    events,
    reffaqs,
    payment,
    notification,
    referral
})