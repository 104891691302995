import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {eventsAPI,joinWebinarAPI,myEventsAPI, eventSubscriptionAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    FETCH_EVENTS_FAILED,
    FETCH_EVENTS_SUCCESS,
    SHOW_LOADING,
    FETCH_EVENTS,
    JOIN_WEBINAR,
    MY_EVENTS,
    MY_EVENTS_FAILED,MY_EVENTS_SUCCESS,
    EVENT_SUBSCRIPTION
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";

function* fetchEvents(action) {

    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(eventsAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_EVENTS_SUCCESS, eventlist: response.data.data});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_EVENTS_FAILED});
    }
}
function* fetchMyEvents(action) {

    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(myEventsAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: MY_EVENTS_SUCCESS, myeventlist: response.data.data});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: MY_EVENTS_FAILED});
    }
}

function* onJoin(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(joinWebinarAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* onEventSubscription(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(eventSubscriptionAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* actionEvents() {
    yield takeLatest(FETCH_EVENTS, fetchEvents)
}
function* actionMyEvents() {
    yield takeLatest(MY_EVENTS, fetchMyEvents)
}
function* actionJoinwebinar() {
    yield takeLatest(JOIN_WEBINAR, onJoin)
}

function* actionEventSubscription() {
    yield takeLatest(EVENT_SUBSCRIPTION, onEventSubscription)
}

export default function* authSaga() {
    yield all([
        fork(actionEvents),
        fork(actionJoinwebinar),
        fork(actionMyEvents),
        fork(actionEventSubscription)
    ]);
}
