import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCollapse,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavLink,
  CDropdown,
  CForm,
  CInput,
  CImg,
  CToggler,
  CBadge,
  CDropdownItem,
  CDropdownToggle,
  CDropdownDivider,
  CNavbarToggler,
  CDropdownMenu,
} from "@coreui/react";
import LogoImage from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { onLogout } from "../../actions/AuthAction";
import { isLoggedIn, isTempLoggedIn } from "../../helpers/utils";
import { Link, useHistory } from "react-router-dom";
import { onFetchNotifications, onReadNotifications } from "../../actions/NotificationAction";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  let history = useHistory();
  let myNotificationList = [{}];
  let myNotificationCount = 0;

  const myNotificationData = useSelector((state) => state.notification.notification.resp);
  const myNotificationCountData = useSelector((state) => state.notification.notification.count);
  if (typeof myNotificationData != "undefined" && myNotificationData != null && myNotificationData.length != null && myNotificationData.length > 0) {
    const slicedArray = myNotificationData.slice(0, 2);
    myNotificationList = slicedArray;
  }
  if (typeof myNotificationCountData != "undefined" && myNotificationCountData != null) {
    myNotificationCount = myNotificationCountData;
  }
  useEffect(() => {
    if (isLoggedIn() && !isTempLoggedIn()) {
      //window.scrollTo(0, 400);
      dispatch(onFetchNotifications())
    }
  }, []);

  const onSuccess = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    history.push("/login");
  };

  const onFailed = () => {
    history.push("/login");
  };

  const logoutUser = () => {
    dispatch(onLogout(onSuccess, onFailed));
  };

  const onClickRedirect = (redirectLink, notifId) => {
    // console.log(redirectLink, 'redirectLink')
    dispatch(onFetchNotifications())
    dispatch(onReadNotifications(notifId))
    if (redirectLink === 'my_events') {
      history.push('/my-events')
    } else if (redirectLink === 'my_lending_orders') {
      history.push('/my-lending-orders')
    } else if (redirectLink === 'completed_courses') {
      history.push('/my-courses/completed')
    } else if (redirectLink === 'enrolled_courses') {
      history.push('/my-courses/enrol')
    } else if (redirectLink === 'my_subscription') {
      history.push('/profile/my-subscription')
    }
  }
  // console.log(myNotificationList, 'myNotificationList')

  return (
    <>
      <CCard className='NavigationBar'>
        <CCardBody>
          <CNavbar expandable="md">
            <CToggler inNavbar onClick={() => setIsOpen(!isOpen)} />
            <CNavbarBrand>
              <Link to="/">  <img className="header-log-tablet-view" src={LogoImage} alt='logo' /></Link>
            </CNavbarBrand>
            <CCollapse show={isOpen} navbar>
              {/* {(isLoggedIn() && <CNavbarNav className="ml-auto">
                            <CNavLink onClick={() => history.push('/home')}>Home</CNavLink>
                            <CNavLink onClick={() => history.push('/aboutus')}>About Us</CNavLink>
                            <CButton onClick={logoutUser} className='Register'>Logout</CButton>
                        </CNavbarNav>) || 
                        <CNavbarNav className="ml-auto">
                            <CNavLink onClick={() => history.push('/home')}>Home</CNavLink>
                            <CNavLink onClick={() => history.push('/aboutus')}>About Us</CNavLink>
                            <CButton onClick={() => history.push('/login')} className='Login'>Sign in</CButton>
                            <CButton onClick={() => history.push('/register')} className='Register'>Create Account</CButton>
                        </CNavbarNav>} */}

              {(isLoggedIn() || isTempLoggedIn()) ?
                <CNavbarNav className="ml-auto">
                  {isLoggedIn() && (<div className='notificationDropdown'>
                    <CBadge color="danger" position="top" shape="rounded-pill">
                      {myNotificationCount}
                    </CBadge>
                    <CDropdown alignment="end">
                      <CDropdownToggle color="secondary"><i className="fa fa-bell-o bell-notification-icon"></i></CDropdownToggle>
                      <CDropdownMenu>
                        {myNotificationList && myNotificationData && myNotificationData.length > 0 ? <>
                          {
                            myNotificationList.map((myNotification, index) => {
                              return <CDropdownItem className={myNotification.read_status == 1 ? "readMenuNotif" : "unreadMenuNotif"}
                                onClick={() => onClickRedirect(myNotification.relation_type, myNotification.id)}>
                                <span class="w-75 text-truncate">{myNotification.title}</span>
                                <div style={{ position: 'absolute', right: 30 , fontSize: '10px'}}>
                                  <span>{myNotification.time_ago}</span>
                                </div>
                              </CDropdownItem>
                            })
                          }    </>
                          : <p style={{ textAlign: 'center' }}>No new notifications</p>}
                        <CDropdownDivider />
                        {myNotificationList && myNotificationData && myNotificationData.length > 1 && <CDropdownItem onClick={() => history.push('/notifications')} className='AllNotification' style={{ 'color': '#fff' }}>See All Notifications</CDropdownItem>}
                      </CDropdownMenu>
                    </CDropdown>
                  </div>)}
                  <CNavLink onClick={() => history.push('/home')}>Home</CNavLink>
                  <CButton onClick={logoutUser} className='Register'>Logout</CButton>
                </CNavbarNav> : <CNavbarNav className="ml-auto">
                  <CNavLink onClick={() => history.push('/home')}>Home</CNavLink>
                  <CNavLink onClick={() => history.push('/about-us')}>About&nbsp;Us</CNavLink>
                  <CNavLink onClick={() => history.push('/course-preview')}>Course&nbsp;Preview</CNavLink>
                  <CButton onClick={() => history.push('/login')} className='Login'>Sign&nbsp;in</CButton>
                  <CButton onClick={() => history.push('/register')} className='Register'>Create&nbsp;Account</CButton>
                </CNavbarNav>}
            </CCollapse>
          </CNavbar>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Header;
