import {all, put, takeLatest} from 'redux-saga/effects';

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* increment() {
    yield put({type: 'INCREMENT'})
}

function* decrement() {
    yield put({type: 'DECREMENT'})
}

function* incrementAsync() {
    yield delay(1000);
    yield put({type: 'INCREMENT'})
}

function* watchIncrementAsync() {
    yield takeLatest('INCREMENT_ASYNC', incrementAsync)
}


export default function* increamentSaga() {
    yield all([
        watchIncrementAsync(),
        increment(),
        decrement()
    ]);
}
