import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {faqAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    FETCH_FAQ_FAILED,
    FETCH_FAQ_SUCCESS,
    SHOW_LOADING,
    FETCH_FAQ,
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";

function* fetchFAQ() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(faqAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_FAQ_SUCCESS, faqs: response.data.data});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_FAQ_FAILED});
    }
}

function* actionFAQ() {
    yield takeLatest(FETCH_FAQ, fetchFAQ)
}


export default function* authSaga() {
    yield all([
        fork(actionFAQ),
    ]);
}
