import React, {useEffect, useRef,useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify';
import {useForm} from "react-hook-form";
import {CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CFormGroup, CLabel, CRow, CFormText, CTooltip} from '@coreui/react'
import {isLoggedIn, setMaxLength, setMinLength} from "../../../helpers/utils";
import { onReset,onValidateResetLink} from "../../../actions/AuthAction";
import { useHistory } from "react-router-dom";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../../constants/Constants";
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Banner from '../../../components/banner'
import SignInImage from "../../../assets/signupimage.png"
const ResetPassword = (props) => {
    const token = props.match.params.token;
    console.log('token', token, props.location.search)
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const [emailid, setEmailid] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    useEffect(()=>{
        let url=window.location.href;
        let email='';
        let paramString = url.split('?')[1];
           if(paramString){
            email = paramString.split('=')[1];
              setEmailid(email);
              let data={
                email:email,
                token: token
               }  
             
         dispatch(onValidateResetLink(data, onSuccessValidate));
           } 
    }, []);

    
    const onSuccessValidate = (responseData) => {
        setDisableButton(false);
        console.log(responseData,'responseData')
        if(responseData.data.status=="INVALID"){
            toast.error(responseData.message);
            setDisableButton(true)
        }

    };
    const onSuccess = (responseData) => {
        history.push('/login');
    };

    const onSubmit = (data) => {
        data.email=emailid;
        data.token = token;
        dispatch(onReset(data, onSuccess));
    };

    if(isLoggedIn()){
        return <Redirect to={'/my-courses/enrol'} />
    }

    return (
        <>
        <Header/>
        <Banner mainTitle={[<h1 key="main">Reset Password</h1>]} pageName={'Reset Password'}/>
        <div className="LoginWrap">
            <div className='InnerWrap'>
                <CRow>
                    <CCol md="5">
                        <CCardGroup>
                            <CCard>
                                <CCardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h1>Reset Your Password</h1>
                                        <CFormGroup>
                                            <CLabel htmlFor="nf-email">Email <span style={{ color: 'red' }}>* </span></CLabel>
                                            <input readonly={true} value={emailid}  placeholder={'Enter Email'} className={'form-control'} {...register("email")} />
                                            {errors.email?.type === 'required' && <CFormText className="help-block">Please enter your email</CFormText>}
                                            {errors.email?.type === 'pattern' && <CFormText className="help-block">Please enter valid email</CFormText>}
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="nf-password">
                                                New Password <span style={{ color: 'red' }}>* </span>{" "} <CTooltip content="Minimum 6 and maximum 18 characters, at least one uppercase letter, one lowercase letter, one number and one special character">
                                                <i className="ml-1 fa fa-info-circle" aria-hidden="true"></i>
                                            </CTooltip>
                                            </CLabel>
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                className={"form-control"}
                                                {...register("password", {
                                                    required: true,
                                                    minLength: setMinLength(6), maxLength: setMaxLength(18),
                                                    pattern:{value:PASSWORD_REGEX, message: 'Please enter a valid password (includes at least one uppercase,lowercase, number and special character)' }
                                                })}
                                            />
                                            {errors.password?.type === "required" && (
                                                <CFormText className="help-block">
                                                Please enter new password
                                                </CFormText>
                                                )}
                                            {errors.password ?.type === 'pattern' && <CFormText className="help-block">{`Please enter a valid password (includes at least one uppercase,lowercase, number and special character)`}</CFormText>}

                                            {(errors.password ?.type === 'maxLength' || errors.password ?.type === 'minLength') && <CFormText className="help-block">{errors.password.message}</CFormText>}

                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="nf-password">Confirm Password <span style={{ color: 'red' }}>* </span></CLabel>
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                className={"form-control"}
                                                {...register("confirm_password", {
                                                    required: true,
                                                    // minLength: setMinLength(6), maxLength: setMaxLength(18),
                                                    //pattern: PASSWORD_REGEX,
                                                    validate: (value) =>
                                                        value === password.current ||
                                                        "Password do not match",
                                                })}
                                            />
                                            {errors.confirm_password?.type === "required" && (
                                                <CFormText className="help-block">
                                                Please enter confirm password
                                                </CFormText>
                                                )}
                                            {/* {errors.confirm_password ?.type === 'pattern' && <CFormText className="help-block">{`Password must have atleast 6 & atmost 18 characters, One uppercase & one lowercase, One number & one special character.`}</CFormText>} */}

                                            {errors.confirm_password?.type === "validate" && (
                                                <CFormText className="help-block">
                                                Password do not match
                                                </CFormText>
                                                )}
                                            {(errors.confirm_password ?.type === 'maxLength' || errors.confirm_password ?.type === 'minLength') && <CFormText className="help-block">{errors.confirm_password.message}</CFormText>}

                                        </CFormGroup>

                                        <div className='ButtonWrap'>
                                            <CButton type={'submit'} disabled={disableButton} color="primary" className="Login">Submit</CButton>
                                            <CButton color="primary" className="Register" active tabIndex={-1}><Link to="/login">
                                                Login</Link></CButton>
                                        </div>
                                    </form>
                                </CCardBody>
                            </CCard>

                        </CCardGroup>
                    </CCol>
                    <CCol md="7">
                        <CCard className="ImageWrapSignIn">
                            <CCardBody>
                                <img src={SignInImage} alt='signin'/>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default ResetPassword
