export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const ALPHA_REGEX = /^[a-zA-Z ]*$/;
export const NUM_REGEX = /^[0-9]*$/;
export const ALPHA_NUM_SPACE_REGEX = /^[a-zA-Z0-9 ]*$/;
export const ALPHA_NUM_COMMA_REGEX='/^[a-zA-Z0-9,.!? ]*$/'
export const ALPHA_NUM_REGEX = /^[a-zA-Z0-9]*$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,18}$/;
export const NAME_VALIDATION = /^[a-zA-Z&-\s]+$/;
export const DECIMAL_VALIDATION = /^\d+(\.\d{1,2})?$/;
// export const ZERO_VALIDATION = /^[1-9]\d{0,5}(\.\d{4})*(,\d+)?$/;
export const ZERO_VALIDATION = /^[1-9]\d*(\.\d+)?$/

// ^([1-9]\d*|0)(\.\d+)?$
// ^[1-9]\d*(\.\d+)?$