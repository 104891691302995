import {
    FETCH_REFERRAL, FETCH_REFERRAL_SUCCESS, FETCH_BONUS, FETCH_BONUS_SUCCESS
} from "../constants/ActionTypes";

const initialState = {
    referral: {},
    bonus: {}
};

export default function referral(state = initialState, action) {
    switch (action.type) {
        case FETCH_REFERRAL:
            return { ...state, loading: true };
        case FETCH_REFERRAL_SUCCESS:
            return { ...state, referral: action.referral, loading: false };
        case FETCH_BONUS:
            return { ...state, loading: true };
        case FETCH_BONUS_SUCCESS:
            return { ...state, bonus: action.bonus, loading: false };
        default:
            return state
    }
}