import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { pageAPI } from "../apis/Api";
import {
    HIDE_LOADING,
    SHOW_LOADING, FETCH_PAGE,
    FETCH_PAGE_SUCCESS, FETCH_PAGE_FAILED,
} from "../constants/ActionTypes";
import { errorMessage } from "../helpers/utils";


function* fetchPageContent(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(pageAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        yield put({ type: FETCH_PAGE_SUCCESS, payload: response.data.data });
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
        yield put({ type: FETCH_PAGE_FAILED });
    }
}


function* actionPages() {
    yield takeLatest(FETCH_PAGE, fetchPageContent)
}


export default function* commonSaga() {
    yield all([
        fork(actionPages)
    ]);
}
