import axios from 'axios';
import { API, BASE_URL } from "../constants/Config";
import { loggedInUserData } from "../helpers/utils";
const userData = loggedInUserData();
export const AxiosInstace = axios.create({
    baseURL: BASE_URL
});

AxiosInstace.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
        config.headers = {
            'Authorization': `${token}`,
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

/*AxiosInstace.interceptors.response.use(response => {
    return response;
}, err => {
    console.log('in Error');
    return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if (err.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }


        return Promise.reject(err);
    });
});*/

export function userLogin(data) {
    return AxiosInstace.post(API.login, data);
}

export function userProfile() {
    return AxiosInstace.get(API.userProfile);
}

export function userRegister(data) {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
        if (key === 'image' && data.image && data.image.length > 0) {
            formData.append("image", data.image[0]);
        } else {
            formData.append(key, data[key]);
        }
    });

    return axios.post(BASE_URL + '/' + API.register, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    //return AxiosInstace.post(API.register, data);
}

export function userUpdateAPI(data) {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
        formData.append("image", data.image[0])
        if (key === 'image' && data.image && data.image.length > 0) {
            formData.append("image", data.image[0]);
        } else {
            formData.append(key, data[key]);
        }
    });
    console.log('data.image',data.image,...formData)

    return axios.post(BASE_URL + '/' + API.editProfile, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    })
    //return AxiosInstace.post(API.register, data);
}

export function userLogout() {
    return AxiosInstace.post(API.logout);
}

export function forgotPasswordAPI(data) {
    return AxiosInstace.post(API.forgotPassword, data);
}

export function changePasswordAPI(data) {
    return AxiosInstace.post(API.changePassword, data);
}

export function resetPasswordAPI(data) {
    return AxiosInstace.post(API.resetPassword, data);
}

export function masterDataAPI() {
    return AxiosInstace.get(API.masters);
}

export function plansAPI() {
    return AxiosInstace.get(API.membership);
}

export function checkPromoCode(code) {
    return AxiosInstace.post(API.checkPromoCode, { promo_code: code });
}

export function stateMasterAPI(country = 231) {
    return AxiosInstace.get(`${API.states}/${country}`);
}

export function cityMasterAPI(keyword) {
    return AxiosInstace.get(`${API.city}?search=${keyword}`);
}


export function timeZoneAPI() {
    return AxiosInstace.get(`${API.timezone}`);
}

export function userList() {
    return AxiosInstace.get(API.users);
}

export function buyMembershipAPI(data) {
    return AxiosInstace.post(API.billing, data);
}

export function freeMembershipAPI(data) {
    return AxiosInstace.post(API.free_membership, data);
}

export function planFeatureAPI(data) {
    return AxiosInstace.post(API.membershipFeatures, data);
}

export function allCourseAPI(category = '') {
    return AxiosInstace.post(API.courses, { filter_by: category });
}

export function categoriesAPI() {
    return AxiosInstace.get(API.categories);
}

export function courseDetailAPI(id) {
    return AxiosInstace.get(API.courseDetail + '/' + id);
}

export function enrollCourseAPI(id) {
    return AxiosInstace.post(API.enrollCourse, { course_id: id });
}

export function unEnrollCourseAPI(id) {
    return AxiosInstace.post(API.unEnrollCourse, { id: id });
}

export function bookmarkCourseAPI(id) {
    return AxiosInstace.post(API.bookmarkCourse, { course_id: id });
}

export function unBookmarkCourseAPI(id) {
    return AxiosInstace.post(API.unBookmarkCourse, { id: id });
}

export function myCoursesAPI(data) {
    return AxiosInstace.post(API.myCourses, data);
}

export function watchCourseStatusAPI(data) {
    return AxiosInstace.post(API.courseWatchStatus, data);
}

export function quizAPI(data) {
    return AxiosInstace.get(`${API.courseQuiz}/${data.id}/${data.quiz_type}`);
}

export function quizSubmitAPI(data) {
    return AxiosInstace.post(API.quizSubmit, data);
}

export function ratingSubmitAPI(data) {
    return AxiosInstace.post(API.addCourseRating, data);
}

export function evaluationAPI(data) {
    return AxiosInstace.get(`${API.getEvaluation}/${data.id}/${data.type}`);
}

export function evaluationSubmitAPI(data) {
    return AxiosInstace.post(API.evaluationSubmit, data);
}

export function submitCEUsAPI(data) {

    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
        if (key === 'certification_doc') {
            formData.append("certification_doc", data.certification_doc[0]);
        } else {
            formData.append(key, data[key]);
        }
    });

    return axios.post(BASE_URL + '/' + API.addOtherCourse, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function updateCourseAPI(data) {
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
        if (key === 'certification_doc' && data.certification_doc && data.certification_doc.length > 0) {
            formData.append("certification_doc", data.certification_doc[0]);
        } else {
            formData.append(key, data[key]);
        }
    });

    return axios.post(BASE_URL + '/' + API.editOtherCourse, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function deleteCourseAPI(id) {
    return AxiosInstace.get(`${API.removeOtherCourse}/${id}`);
}

export function myCEUsAPI(year) {
    return AxiosInstace.post(API.myCEU, year ? { year: year } : {});
}

export function affiliationAPI() {
    return AxiosInstace.get(API.affiliation);
}

export function lendingItemsAPI(data) {
    return AxiosInstace.post(API.lendingItems, data);
}

export function addShippingDetailslendingAPI(data) {
    return AxiosInstace.post(API.addLendingShippingDetails, data);
}

export function editShippingDetailslendingAPI(data) {
    return AxiosInstace.post(API.editLendingShippingDetails + '/' + data.id, data);
}

export function paymentInformationAPI() {
    return AxiosInstace.post(API.paymentInformation, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function placeOrderLendingAPI(data) {
    return AxiosInstace.post(API.placeOrderLending, data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function editBillingInfoAPI(data) {
    return AxiosInstace.post(API.editBillingInfo, data, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function editShippingMethodAPI(data) {
    return AxiosInstace.get(API.editShippinMethod + '/' + data.zip + '/' + data.quantity + '/' + data.weight, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        }
    });
}

export function myLendingOrdersAPI() {
    return AxiosInstace.post(API.myLendingOrder, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function fetchShippingDetailsAPI() {
    return AxiosInstace.post(API.shippingDetails, {
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function resourceItemsAPI(data) {
    return AxiosInstace.post(API.resourceItems, data.id ? { id: data.id } : data);
}
export function submitCUsAPI(data) {
    const formData = new FormData();
    const keys = Object.keys(data);
    let mail = [];
    mail.push(data.cc_email)
    data.cc_email = mail;

    keys.forEach((key, index) => {
        // console.log(data)
        if (key === 'attachment') {
            formData.append("attachment", data.attachment[0]);

        } else {
            formData.append(key, data[key]);
        }

    });
    return axios.post(BASE_URL + '/' + API.contactUs, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function submitEndCUsAPI(data) {
    const formData = new FormData();
    const keys = Object.keys(data);
    let mail = [];
    mail.push(data.email)
    data.email = mail;

    keys.forEach((key, index) => {
        // console.log(data)
        if (key === 'attachment') {
            formData.append("attachment", data.attachment[0]);

        } else {
            formData.append(key, data[key]);
        }

    });
    return axios.post(BASE_URL + '/' + API.contactEndUs, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
        }
    });
}

export function fetchNotificationAPI() {
    return AxiosInstace.get(BASE_URL + '/' + API.notification, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        }
    });
}

export function readNotificationAPI(data) {
    return AxiosInstace.post(BASE_URL + '/' + API.readNotification, { notfication_id: data }, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        }
    });
}

export function fetchReferralAPI() {
    return AxiosInstace.get(BASE_URL + '/' + API.fetchReferral, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        }
    });
}

export function fetchBonusAPI() {
    return AxiosInstace.get(BASE_URL + '/' + API.fetchBonus, {
        headers: {
            'Authorization': localStorage.getItem('token'),
        }
    });
}

export function pageAPI(page) {
    return AxiosInstace.post(API.appContent, { type: page });
}
export function eventsAPI(data) {
    return AxiosInstace.post(API.liveEvents, data);
}

export function myEventsAPI(data) {
    return AxiosInstace.post(API.userSubscribedEvent, data);
}
export function joinWebinarAPI(data) {
    return AxiosInstace.post(API.joinEvent, data);
}

export function eventSubscriptionAPI(data) {
    return AxiosInstace.post(API.eventSubscription, data);
}

export function faqAPI() {
    return AxiosInstace.post(API.faqs, { type: 'REFERRALFAQ' });
}

export function appContentAPI(data) {
    return AxiosInstace.post(API.appContent, data);
}

export function onResendEmailAPI(data) {
    return AxiosInstace.post(API.emailResend, data);
}
export function onSampleCourselAPI(data) {
    return AxiosInstace.post(API.offeredCourses, data);
}

export function onValidateResetAPI(data) {
    return AxiosInstace.post(API.validateReset, data);
}

export function onPlaceOrderAPI(data) {
    return AxiosInstace.post(API.placeOrder, data);
}
export function allLendingItemsAPI() {
    return AxiosInstace.get(API.allLendingItems);
}
export function onAllResourceItemsAPI(data) {
    return AxiosInstace.post(API.allResourceItems, data);
}
// export function onUpdatePlanAPI(data) {
//     return AxiosInstace.post(API.updateAutoRenew, data);
// }
export function onRenewPlanAPI(data) {
    return AxiosInstace.post(API.renewSubscription, data);
}
export function onCancelPlanAPI() {
    return AxiosInstace.post(API.cancelSubscription);
}

export function initiateRefundAPI(data) {
    return AxiosInstace.post(API.initiate_refund, data);
}
