import {all} from 'redux-saga/effects';
import newsSaga from "./newsSaga";
import increamentSaga from "./incrementSaga";
import authSaga from "./authSaga";
import courseSaga from "./courseSaga";
import ceusSaga from "./ceusSaga";
import lendingSaga from "./lendingSaga";
import resourceSaga from "./resourceSaga";
import contactusSaga from "./contactusSaga";
import commonSaga from "./commonSaga";
import eventsSaga from "./eventsSaga";
import faqSaga from "./faqSaga";
import paymentSaga from './paymentSaga';
import notificationSaga from './notificationSaga';
import referralSaga from './referralSaga' ;

export default function* rootSaga() {
    yield all([
        authSaga(),
        newsSaga(),
        increamentSaga(),
        courseSaga(),
        ceusSaga(),
        lendingSaga(),
        resourceSaga(),
        contactusSaga(),
        commonSaga(),
        eventsSaga(),
        faqSaga(),
        paymentSaga(),
        notificationSaga(),
        referralSaga()
    ]);
}
