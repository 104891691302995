import {all, put, takeLatest} from 'redux-saga/effects';

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* fetchNews() {
    yield put({type: "SHOW_LOADING"});
    yield delay(2000);
    const json = yield fetch('https://newsapi.org/v1/articles?source=cnn&apiKey=c39a26d9c12f48dba2a5c00e35684ecc')
        .then(response => response.json());

    yield put({type: "NEWS_RECEIVED", json: json.articles || [{error: json.message}]});
    yield put({type: "HIDE_LOADING"});
}

function* actionWatcher() {
    yield takeLatest('GET_NEWS', fetchNews)
}

/*function* loginFlow() {
    while (true) {
        yield take('NEWS_RECEIVED')
        alert('news recieved');
        yield take('GET_NEWS')
        alert('newscalleed')
    }
}*/


export default function* newsSaga() {
    yield all([
        actionWatcher(),
        //loginFlow()
    ]);
}
