import {
    FETCH_NOTIFICATION_LIST, NOTIFICATION_LIST_SUCCESS, READ_NOTIFICATION, READ_NOTIFICATION_SUCCESS
} from "../constants/ActionTypes";

const initialState = {
    notification: {}
};

export default function lending(state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case NOTIFICATION_LIST_SUCCESS:
            return { ...state, notification: action.notification, loading: false };
        case READ_NOTIFICATION:
            return { ...state, loading: true };
        case READ_NOTIFICATION_SUCCESS:
            return { ...state, loading: false };
        default:
            return state
    }
}