import {
    FETCH_NOTIFICATION_LIST, READ_NOTIFICATION
} from "../constants/ActionTypes";

export const onFetchNotifications = () => ({
    type: FETCH_NOTIFICATION_LIST
});

export const onReadNotifications = (data) => ({
    type: READ_NOTIFICATION, payload: data
});