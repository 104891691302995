import {
    FETCH_FAQ_FAILED,
    FETCH_FAQ_SUCCESS,
} from "../constants/ActionTypes";

const initState = {
    faqs: []
}
export default function course(state = initState, action) {
    switch (action.type) {
        case FETCH_FAQ_SUCCESS:
            return {...state, faqs: action.faqs};
        case FETCH_FAQ_FAILED:
            return {...state, faqs: []};
     
        default:
            return state
    }
}