import {
    CHANGE_PASSWORD, CHECK_PROMOCODE, FETCH_MASTERS, FETCH_PLANS, FORGOT, LOGIN, LOGOUT, MEMBERSHIP,
    REGISTER, RESET_PASSWORD, UPDATE_USER_PROFILE, USER_PROFILE,FETCH_PLAN_FEATURE,FETCH_APPCONTENT,
    RESEND_PASSWORD_LINK,SAMPLE_COURSE,VALIDATE_RESET_LINK,UPDATE_PLAN_STATUS,RENEW_PLAN,CANCEL_PLAN,
    INITIATE_REFUND,
    FETCH_CITIES, FREE_MEMBERSHIP
} from "../constants/ActionTypes";


export const onLogin = (data, onSuccess) => ({
    type: LOGIN, payload: data, onSuccess
});

export const onReset = (data, onSuccess) => ({
    type: RESET_PASSWORD, payload: data, onSuccess
});
export const onValidateResetLink = (data, onSuccessValidate) => ({
    type: VALIDATE_RESET_LINK, payload: data, onSuccessValidate
});


export const onRegister = (data, onSuccess) => ({
    type: REGISTER, payload: data, onSuccess
});

export const onEditProfile = (data, onSuccess) => ({
    type: UPDATE_USER_PROFILE, payload: data, onSuccess
});

export const onChangePassword = (data, onSuccess) => ({
    type: CHANGE_PASSWORD, payload: data, onSuccess
});

export const onForgotPassword = (data, onSuccess) => ({
    type: FORGOT, payload: data, onSuccess
});
export const onResendpasswordLink = (data, onSuccess) => ({
    type: RESEND_PASSWORD_LINK, payload: data, onSuccess
});

export const onLogout = (onSuccess ,onFailed) => ({
    type: LOGOUT, onSuccess: onSuccess,onFailed: onFailed
});

export const onUserProfile = () => ({
    type: USER_PROFILE
});

export const onFetchMaster = () => ({
    type: FETCH_MASTERS
});

export const onFetchCities = (data, onSuccess) => ({
    type: FETCH_CITIES,onSuccess,payload: data
});


export const onFetchPlanMaster = () => ({
    type: FETCH_PLANS
});


export const onFetchContent = (data,onSuccess) => ({
    type: FETCH_APPCONTENT, payload: data, onSuccess
})

export const onFetchPlanfeature = (data,onSuccess) => ({
    type: FETCH_PLAN_FEATURE, payload: data, onSuccess
})

export const onCheckPromoCode = (data, onSuccess) => ({
    type: CHECK_PROMOCODE, payload: data, onSuccess
})

export const onMembership = (data, onSuccess) => ({
    type: MEMBERSHIP, payload: data, onSuccess
});

export const freeMembership = (data, onSuccess) => ({
    type: FREE_MEMBERSHIP, payload: data, onSuccess
});


export const onSampleCourseList = (data, onSuccess) => ({
    type: SAMPLE_COURSE, payload: data, onSuccess
});
export const onChangePlanStatus = (data, onSuccess) => ({
    type: RENEW_PLAN, payload: data, onSuccess
});
export const onCancelPlan = (onSuccess) => ({
    type: CANCEL_PLAN, onSuccess
});

export const onInitiateRefund = (data) => ({
    type: INITIATE_REFUND, payload: data
});