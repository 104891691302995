import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    lendingItemsAPI,
    addShippingDetailslendingAPI, placeOrderLendingAPI, myLendingOrdersAPI, editShippingDetailslendingAPI,
    editBillingInfoAPI,editShippingMethodAPI, fetchShippingDetailsAPI,allLendingItemsAPI
} from "../apis/Api";
import { toast } from 'react-toastify';
import {
    FETCH_LENDING_ITEM,
    HIDE_LOADING, LENDING_ITEM_DETAIL_SUCCESS, LENDING_ITEM_FAILED, LENDING_ITEM_SUCCESS,
    SHOW_LOADING, ADD_SHIPPMENT_DETAILS, ADD_SHIPPING_DETAIL_SUCCESS, LENDING_PLACE_ORDER,
    PLACE_ORDER_FAILED, PLACE_ORDER_SUCCESS, ADD_SHIPPING_USER_DETAIL, MY_LENDING_ORDER_SUCCESS,
    MY_LENDING_ORDER_FAILED, MY_LENDING_ORDER, EDIT_SHIPPMENT_DETAILS, EDIT_BILLING_INFO, EDIT_SHIPPING_METHOD,
    SHIPPING_DETAIL_SUCCESS, FETCH_SHIPPING_DETAIL,ALL_LENDING_ITEM
} from "../constants/ActionTypes";
import { errorMessage } from "../helpers/utils";


function* fetchLendingItems(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(lendingItemsAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        if (action.payload && action.payload.id) {
            yield put({ type: LENDING_ITEM_DETAIL_SUCCESS, lendingItemsDetail: response.data.data });
        } else {
            yield put({ type: LENDING_ITEM_SUCCESS, lendingItems: response.data.data });
        }

    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
        yield put({ type: LENDING_ITEM_FAILED });
    }
}

function* actionLendingItems() {
    yield takeEvery(FETCH_LENDING_ITEM, fetchLendingItems)
}

function* addLendingShippingDetails(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(addShippingDetailslendingAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        yield put({ type: ADD_SHIPPING_DETAIL_SUCCESS, lendingAddShippingDetail: response.data.data.shipping_methods.data });
        yield put({ type: ADD_SHIPPING_USER_DETAIL, lendingAddShippingUserDetail: response.data.data.shipping.data });
        action.onSuccess(response)
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionaddLendingShippingDetails() {
    yield takeEvery(ADD_SHIPPMENT_DETAILS, addLendingShippingDetails)
}

function* editShippingDetailslending(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(editShippingDetailslendingAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        yield put({ type: ADD_SHIPPING_DETAIL_SUCCESS, lendingAddShippingDetail: response.data.data.shipping_methods.data });
        yield put({ type: ADD_SHIPPING_USER_DETAIL, lendingAddShippingUserDetail: response.data.data.shipping.data });
        action.onSuccess(response)
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actioneditLendingShippingDetails() {
    yield takeEvery(EDIT_SHIPPMENT_DETAILS, editShippingDetailslending)
}

function* placeLendingOrder(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(placeOrderLendingAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        yield put({ type: PLACE_ORDER_SUCCESS, placeOrder: response.data });
        action.onSuccess(response)
        if (response.data) {
            toast.success(response.data.message);
        }
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
        yield put({ type: PLACE_ORDER_FAILED });
    }
}

function* actionPlaceLendingOrder() {
    yield takeEvery(LENDING_PLACE_ORDER, placeLendingOrder)
}

function* fetchMyLendingOrder(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(myLendingOrdersAPI);
        yield put({ type: HIDE_LOADING });
        yield put({ type: MY_LENDING_ORDER_SUCCESS, myLendingOrderDetail: response.data.data });

    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
        yield put({ type: MY_LENDING_ORDER_FAILED });
    }
}

function* actionMyLendingOrder() {
    yield takeEvery(MY_LENDING_ORDER, fetchMyLendingOrder)
}

function* editBillingInformation(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(editBillingInfoAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        action.onSuccess(response)
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actioneditBillingInformation() {
    yield takeEvery(EDIT_BILLING_INFO, editBillingInformation)
}

function* editShippinMethod(action) {
    try {
        // yield put({ type: SHOW_LOADING });
        const response = yield call(editShippingMethodAPI, action.payload);
        // yield put({ type: HIDE_LOADING });
        yield put({ type: ADD_SHIPPING_DETAIL_SUCCESS, lendingAddShippingDetail: response.data.data});
        action.onSuccess(response.data.data)
    } catch (error) {
        console.log('response', error)
        errorMessage(error);
        action.onSuccess(false)
        yield put({ type: HIDE_LOADING });
    }
}

function* actioneditShippinMethod() {
    yield takeEvery(EDIT_SHIPPING_METHOD, editShippinMethod)
}

function* fetchShippingDetails(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(fetchShippingDetailsAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        yield put({ type: SHIPPING_DETAIL_SUCCESS, fetchShippingDetail: response.data.data});
        // action.onSuccess(response)
    } catch (error) {
        console.log('response', error)
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionfetchShippingDetails() {
    yield takeEvery(FETCH_SHIPPING_DETAIL, fetchShippingDetails)
}

function* onFetchLendingItemList(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(allLendingItemsAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        action.onSuccess(response.data.data)
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actiononFetchLendingItemList() {
    yield takeEvery(ALL_LENDING_ITEM, onFetchLendingItemList)
}
export default function* lendingSaga() {
    yield all([
        fork(actionLendingItems),
        fork(actionaddLendingShippingDetails),
        fork(actionPlaceLendingOrder),
        fork(actionMyLendingOrder),
        fork(actioneditLendingShippingDetails),
        fork(actioneditBillingInformation),
        fork(actioneditShippinMethod), 
        fork(actionfetchShippingDetails), 
        fork(actiononFetchLendingItemList)
    ]);
}
