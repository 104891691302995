import {
    BOOKMARKED_COURSE_SUCCESS,
    COMPLETED_COURSE_SUCCESS,
    COURSE_DETAIL_FAILED,
    COURSE_DETAIL_SUCCESS,
    FETCH_CATEGORIES_FAILED,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_COURSE_FAILED,
    FETCH_COURSE_SUCCESS, FETCH_QUIZ_FAILED, FETCH_QUIZ_SUCCESS, INPROGRESS_COURSE_SUCCESS,
} from "../constants/ActionTypes";

const initState = {
    courses: [],
    categories: [],
    courseDetail: {},
    inProgressCourses: [],
    completedCourses: [],
    bookmarkedCourses: [],
    quizData: {},
}
export default function course(state = initState, action) {
    switch (action.type) {
        case FETCH_COURSE_SUCCESS:
            return {...state, courses: action.courses};
        case FETCH_COURSE_FAILED:
            return {...state, courses: []};
        case FETCH_CATEGORIES_SUCCESS:
            return {...state, categories: action.categories};
        case FETCH_CATEGORIES_FAILED:
            return {...state, categories: []};
        case COURSE_DETAIL_SUCCESS:
            return {...state, courseDetail: action.courseDetail};
        case COURSE_DETAIL_FAILED:
            return {...state, courseDetail: {}};
        case INPROGRESS_COURSE_SUCCESS:
            return {...state, inProgressCourses: action.inProgressCourses};
        case COMPLETED_COURSE_SUCCESS:
            return {...state, completedCourses: action.completedCourses};
        case BOOKMARKED_COURSE_SUCCESS:
            return {...state, bookmarkedCourses: action.bookmarkedCourses};
        case FETCH_QUIZ_SUCCESS:
            return {...state, quizData: Array.isArray(action.quizData)?{}:action.quizData};
        case FETCH_QUIZ_FAILED:
            return {...state, quizData: {}};
        default:
            return state
    }
}