import {
    LENDING_ITEM_SUCCESS, LENDING_ITEM_FAILED, LENDING_ITEM_DETAIL_SUCCESS, ADD_SHIPPING_USER_DETAIL,
    LENDING_ITEM_DETAIL_FAILED, ADD_SHIPPING_DETAIL_SUCCESS, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAILED,
    MY_LENDING_ORDER_SUCCESS, MY_LENDING_ORDER_FAILED, SHIPPING_DETAIL_SUCCESS
} from "../constants/ActionTypes";

const initialState = {
    lendingItems: [],
    lendingItemsDetail: {},
    lendingAddShippingDetail: [],
    lendingAddShippingUserDetail: [{}],
    placeOrder: [],
    myLendingOrderDetail: [],
    fetchShippingDetail: []
};

export default function lending(state = initialState, action) {
    switch (action.type) {
        case LENDING_ITEM_SUCCESS:
            return { ...state, lendingItems: action.lendingItems }
        case LENDING_ITEM_FAILED:
            return { ...state, lendingItems: [], lendingItemsDetail: {} }
        case LENDING_ITEM_DETAIL_SUCCESS:
            return { ...state, lendingItemsDetail: action.lendingItemsDetail }
        case ADD_SHIPPING_DETAIL_SUCCESS:
            return { ...state, lendingAddShippingDetail: action.lendingAddShippingDetail }
        case ADD_SHIPPING_USER_DETAIL:
            return { ...state, lendingAddShippingUserDetail: action.lendingAddShippingUserDetail }
        case PLACE_ORDER_SUCCESS:
            return { ...state, placeOrder: action.placeOrder }
        case PLACE_ORDER_FAILED:
            return { ...state, placeOrder: [] }
        case MY_LENDING_ORDER_SUCCESS:
            return { ...state, myLendingOrderDetail: action.myLendingOrderDetail }
        case MY_LENDING_ORDER_FAILED:
            return { ...state, myLendingOrderDetail: [] }
        case SHIPPING_DETAIL_SUCCESS:
            return { ...state, fetchShippingDetail: action.fetchShippingDetail }
        default:
            return state
    }
}