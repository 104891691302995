import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchNotificationAPI, readNotificationAPI } from "../apis/Api";
import { toast } from 'react-toastify';
import {
    FETCH_NOTIFICATION_LIST,
    NOTIFICATION_LIST_SUCCESS, HIDE_LOADING, SHOW_LOADING, READ_NOTIFICATION, READ_NOTIFICATION_SUCCESS
} from "../constants/ActionTypes";
import { errorMessage } from "../helpers/utils";

function* fetchNotification() {
    try {
        // yield put({ type: SHOW_LOADING });
        const response = yield call(fetchNotificationAPI);
        // console.log(response, 'response')
        // yield put({ type: HIDE_LOADING });
        yield put({ type: NOTIFICATION_LIST_SUCCESS, notification: response.data.data });
    } catch (error) {
        // console.log('response', error)
        errorMessage(error);
        // yield put({ type: HIDE_LOADING });
    }
}

function* actionNotification() {
    yield takeEvery(FETCH_NOTIFICATION_LIST, fetchNotification)
}

function* readNotification(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(readNotificationAPI, action.payload);
        // console.log(response, 'response')
        yield put({ type: HIDE_LOADING });
        yield put({ type: READ_NOTIFICATION_SUCCESS, notification: response.data.data });
    } catch (error) {
        // console.log('response', error)
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionreadNotification() {
    yield takeEvery(READ_NOTIFICATION, readNotification)
}

export default function* notificationSaga() {
    yield all([
        fork(actionNotification),
        fork(actionreadNotification),
    ]);
}
