import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {resourceItemsAPI,onAllResourceItemsAPI} from "../apis/Api";
import {
    FETCH_RESOURCE_ITEM,
    HIDE_LOADING, RESOURCE_ITEM_FAILED, VIDEO_ITEM_SUCCESS,
    SHOW_LOADING, VIDEO_RESOURCE_DETAIL, DOC_ITEM_SUCCESS, PPT_ITEM_SUCCESS, IMAGE_ITEM_SUCCESS,DOC_ITEM_DETAIL
    ,IMAGE_RESOURCE_DETAIL,PPT_RESOURCE_DETAIL,ALL_RESOURCE_ITEM
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";


function* fetchResourceItems(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(resourceItemsAPI, action.payload);
        yield put({type: HIDE_LOADING});
        if(action.payload.type === 'VIDEO' && action.payload.id) {
            yield put({type: VIDEO_RESOURCE_DETAIL, payload: response.data.data});
        } else if(action.payload.type === 'VIDEO') {
            yield put({type: VIDEO_ITEM_SUCCESS, videoResource: response.data.data});
        } else if(action.payload.type === 'PUBLICATION'  && !action.payload.id) {
            yield put({type: DOC_ITEM_SUCCESS, docResource: response.data.data});
        } else if(action.payload.type === 'POWERPOINT' && !action.payload.id) {
            yield put({type: PPT_ITEM_SUCCESS, PPTResource: response.data.data});
        } else if(action.payload.type === 'IMAGE'  && !action.payload.id) {
            yield put({type: IMAGE_ITEM_SUCCESS, imageResource: response.data.data});
        }
        else if(action.payload.type === 'PUBLICATION'  && action.payload.id) {
            yield put({type: DOC_ITEM_DETAIL, docDetail: response.data.data});
        }
        else if(action.payload.type === 'IMAGE'  && action.payload.id) {
            yield put({type: IMAGE_RESOURCE_DETAIL, imgDetail: response.data.data});
        }
        else if(action.payload.type === 'POWERPOINT'  && action.payload.id) {
            yield put({type: PPT_RESOURCE_DETAIL, pptDetail: response.data.data});
        }
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: RESOURCE_ITEM_FAILED});
    }
}


function* actionResourceItems() {
    yield takeLatest(FETCH_RESOURCE_ITEM, fetchResourceItems)
}

function* onFetchResourceItemList(action) {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(onAllResourceItemsAPI, action.payload);
        yield put({ type: HIDE_LOADING });
        action.onSuccess(response.data.data)
    } catch (error) {
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionOnFetchResourceItemList() {
    yield takeLatest(ALL_RESOURCE_ITEM, onFetchResourceItemList)
}

export default function* resourceSaga() {
    yield all([
        fork(actionResourceItems),
        fork(actionOnFetchResourceItemList)
    ]);
}
