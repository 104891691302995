import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchReferralAPI, fetchBonusAPI } from "../apis/Api";
import { toast } from 'react-toastify';
import {
    FETCH_REFERRAL, FETCH_REFERRAL_SUCCESS, HIDE_LOADING, SHOW_LOADING, FETCH_BONUS, FETCH_BONUS_SUCCESS
} from "../constants/ActionTypes";
import { errorMessage } from "../helpers/utils";

function* fetchReferral() {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(fetchReferralAPI);
        // console.log(response, 'response')
        yield put({ type: HIDE_LOADING });
        yield put({ type: FETCH_REFERRAL_SUCCESS, referral: response.data.data });
    } catch (error) {
        // console.log('response', error)
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionReferral() {
    yield takeEvery(FETCH_REFERRAL, fetchReferral)
}

function* fetchBonus() {
    try {
        yield put({ type: SHOW_LOADING });
        const response = yield call(fetchBonusAPI);
        console.log(response.data.data, 'responseData')
        yield put({ type: HIDE_LOADING });
        yield put({ type: FETCH_BONUS_SUCCESS, bonus: response.data.data });
    } catch (error) {
        // console.log('response', error)
        errorMessage(error);
        yield put({ type: HIDE_LOADING });
    }
}

function* actionBonus() {
    yield takeEvery(FETCH_BONUS, fetchBonus)
}

export default function* referralSaga() {
    yield all([
        fork(actionReferral),
        fork(actionBonus),
    ]);
}
