import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import PageLoading from './components/PageLoading'
import ResetPassword from "./views/pages/login/ResetPassword";
import ResendVerificationLink from "./views/pages/login/ResendVerificationLink";
import { isLoggedIn, isTempLoggedIn } from "./helpers/utils";
import ScrollToTop from './components/ScrollToTop'

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
)
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Forgot = React.lazy(() => import('./views/pages/login/Forgot'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Membership = React.lazy(() => import('./views/pages/register/Membership'));
//const Membership = React.lazy(() => import('./views/pages/other/Membership'));
const ContactUs = React.lazy(() => import('./views/pages/other/ContactUs'));
const LearnMore = React.lazy(() => import('./views/pages/other/LearnMore'));
const MembershipDetails = React.lazy(() => import('./views/pages/other/LearnMore'));
const Privacy = React.lazy(() => import('./views/pages/other/Privacy'));
const TermsOfUse = React.lazy(() => import('./views/pages/other/TermsOfUse'));
const AboutUs = React.lazy(() => import('./views/pages/other/AboutUs'));
const SampleCourse = React.lazy(() => import('./views/pages/other/SampleCourses'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ResendLink = React.lazy(() => import('./views/pages/login/ResendVerificationLink'));
const CoursePreview = React.lazy(() => import('./views/pages/other/CoursePreview'));
const CoursePreviewDetail = React.lazy(() => import('./views/pages/other/CoursePreviewDetail'));

const App = (props) => {
    const pageLoading = useSelector(state => state.common.loading);
    return (
        <BrowserRouter>
            <ScrollToTop>
                <ToastContainer />
                {pageLoading && <PageLoading loading={true} />}
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
                        <Route exact path="/reset-password/:token" name="Reset Password" render={props => <ResetPassword {...props} />} />
                        <Route exact path="/resend-verification-link" name="Resend Link" render={props => <ResendLink {...props} />} />
                        <Route exact path="/learn-more" name="Learn More" render={props => <LearnMore {...props} />} />
                        <Route exact path="/membership-details" name="Membership Details" render={props => <MembershipDetails {...props} />} />
                        <Route exact path="/membership" name="Membership" render={props => <Membership {...props} />} />
                        <Route exact path="/register" name="Register" render={props => <Register {...props} />} />
                        <Route exact path="/forgot-password" name="Forgot Password"
                            render={props => <Forgot {...props} />} />
                        <Route exact path="/contact-us" name="Contact Us" render={props => <ContactUs {...props} />} />
                        <Route exact path="/course-preview" name="Course Preview" render={props => <CoursePreview {...props} />} />
                        <Route exact path="/course-detail/:id" name="Course Detail" render={props => <CoursePreviewDetail {...props} />} />
                        <Route exact path="/privacy-policy" name="Privacy Policy"
                            render={props => <Privacy {...props} />} />
                        <Route exact path="/terms-conditions" name="Terms of use"
                            render={props => <TermsOfUse {...props} />} />
                        <Route exact path="/about-us" name="About Us"
                            render={props => <AboutUs {...props} />} />
                        <Route exact path="/sample-course" name="Sample Course"
                            render={props => <SampleCourse {...props} />} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                        {/* <Route path="/" name="Home" render={props => <TheLayout {...props} />} /> */}
                        {/* <Redirect to="/login" /> */}
                        {isLoggedIn() && (
                            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                        )
                        }
                        {!isLoggedIn() && !isTempLoggedIn() && (
                            <Redirect to='/login' />
                        )}
                        {isTempLoggedIn() && !isLoggedIn() && (
                            <Redirect to='/membership' />
                        )}
                    </Switch>
                </React.Suspense>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
