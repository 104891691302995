export const GET_NEWS = 'GET_NEWS';
export const INCREMENT_ASYNC = 'INCREMENT_ASYNC';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const FETCH_USERS = 'FETCH_USERS';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const VALIDATE_RESET_LINK = 'VALIDATE_RESET_LINK';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const FORGOT = 'FORGOT';
export const RESEND_PASSWORD_LINK = 'RESEND_PASSWORD_LINK';

export const FETCH_MASTERS = 'FETCH_MASTERS';
export const FETCH_MASTERS_SUCCESS = 'FETCH_MASTERS_SUCCESS';
export const FETCH_MASTERS_FAILED = 'FETCH_MASTERS_FAILED';

export const FETCH_PLANS = 'FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILED = 'FETCH_PLANS_FAILED';

export const CHECK_PROMOCODE = 'CHECK_PROMOCODE';
export const MEMBERSHIP = 'MEMBERSHIP';

export const FETCH_COURSE = 'FETCH_COURSE';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAILED = 'FETCH_COURSE_FAILED';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';

export const COURSE_DETAIL = 'COURSE_DETAIL';
export const COURSE_DETAIL_SUCCESS = 'COURSE_DETAIL_SUCCESS';
export const COURSE_DETAIL_FAILED = 'COURSE_DETAIL_FAILED';

export const BOOKMARK_COURSE = 'BOOKMARK_COURSE';
export const UNBOOKMARK_COURSE = 'UNBOOKMARK_COURSE';
export const ENROLL_COURSE = 'ENROLL_COURSE';
export const UNENROLL_COURSE = 'UNENROLL_COURSE';

export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const FETCH_MYCOURSE = 'FETCH_MYCOURSE';
export const INPROGRESS_COURSE_SUCCESS = 'INPROGRESS_COURSE_SUCCESS';
export const COMPLETED_COURSE_SUCCESS = 'COMPLETED_COURSE_SUCCESS';
export const BOOKMARKED_COURSE_SUCCESS = 'BOOKMARKED_COURSE_SUCCESS';

export const COURSE_WATCH_STATUS = 'COURSE_WATCH_STATUS';
export const FETCH_QUIZ = 'FETCH_QUIZ';
export const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS';
export const FETCH_QUIZ_FAILED = 'FETCH_QUIZ_FAILED';
export const SUBMIT_QUIZ = 'SUBMIT_QUIZ';
export const SUBMIT_QUIZ_SUCCESS = 'SUBMIT_QUIZ_SUCCESS';
export const SUBMIT_QUIZ_FAILED = 'SUBMIT_QUIZ_FAILED';

export const FETCH_COURSE_RATING = 'FETCH_COURSE_RATING';
export const SUBMIT_COURSE_RATING = 'SUBMIT_COURSE_RATING';

export const FETCH_MY_CEUS = 'FETCH_MY_CEUS';
export const MY_CEUS_SUCCESS = 'MY_CEUS_SUCCESS';
export const MY_CEUS_FAILED = 'MY_CEUS_FAILED';
export const SUBMIT_CEUS = 'SUBMIT_CEUS';
export const FETCH_AFFILIATION = 'FETCH_AFFILIATION';
export const FETCH_AFFILIATION_SUCCESS = 'FETCH_AFFILIATION_SUCCESS'
export const UPDATE_CUES = 'UPDATE_CUES'
export const DELETE_CUES = 'DELETE_CUES'


export const FETCH_LENDING_ITEM = 'FETCH_LENDING_ITEM';
export const ADD_SHIPPMENT_DETAILS = 'ADD_SHIPPMENT_DETAILS';
export const EDIT_SHIPPMENT_DETAILS = 'EDIT_SHIPPMENT_DETAILS';
export const ADD_SHIPPING_DETAIL_SUCCESS = 'ADD_SHIPPING_DETAIL_SUCCESS';
export const ADD_SHIPPING_USER_DETAIL = 'ADD_SHIPPING_USER_DETAIL';

export const ALL_LENDING_ITEM = 'ALL_LENDING_ITEM';
export const ALL_RESOURCE_ITEM = 'ALL_RESOURCE_ITEM';

export const LENDING_ITEM_SUCCESS = 'LENDING_ITEM_SUCCESS';
export const LENDING_ITEM_DETAIL_SUCCESS = 'LENDING_ITEM_DETAIL_SUCCESS';
export const LENDING_ITEM_DETAIL_FAILED = 'LENDING_ITEM_DETAIL_FAILED';
export const LENDING_ITEM_FAILED = 'LENDING_ITEM_FAILED';

export const FETCH_SHIPPING_DETAIL = 'FETCH_SHIPPING_DETAIL';
export const SHIPPING_DETAIL_SUCCESS = 'SHIPPING_DETAIL_SUCCESS';

export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';

export const FETCH_REFERRAL = 'FETCH_REFERRAL';
export const FETCH_REFERRAL_SUCCESS = 'FETCH_REFERRAL_SUCCESS';

export const FETCH_BONUS = 'FETCH_BONUS';
export const FETCH_BONUS_SUCCESS = 'FETCH_BONUS_SUCCESS';

export const FETCH_PAYMENT_INFORMATION = 'FETCH_PAYMENT_INFORMATION';
export const PAYMENT_INFORMATION_FAILED = 'PAYMENT_INFORMATION_FAILED';
export const PAYMENT_INFORMATION_SUCCESS = 'PAYMENT_INFORMATION_SUCCESS';
export const BILLING_INFORMATION_SUCCESS = 'BILLING_INFORMATION_SUCCESS';

export const LENDING_PLACE_ORDER = 'LENDING_PLACE_ORDER';
export const PLACE_ORDER_FAILED = 'PLACE_ORDER_FAILED';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const MY_LENDING_ORDER = 'MY_LENDING_ORDER';
export const MY_LENDING_ORDER_SUCCESS = 'MY_LENDING_ORDER_SUCCESS';
export const MY_LENDING_ORDER_FAILED = 'MY_LENDING_ORDER_FAILED';
export const EDIT_BILLING_INFO = 'EDIT_BILLING_INFO';
export const EDIT_SHIPPING_METHOD = 'EDIT_SHIPPING_METHOD';

export const FETCH_RESOURCE_ITEM = 'FETCH_RESOURCE_ITEM';
export const FETCH_PPT_RESOURCE_ITEM = 'FETCH_PPT_RESOURCE_ITEM';
export const VIDEO_ITEM_SUCCESS = 'VIDEO_ITEM_SUCCESS';
export const DOC_ITEM_SUCCESS = 'DOC_ITEM_SUCCESS';
export const IMAGE_ITEM_SUCCESS = 'IMAGE_ITEM_SUCCESS';
export const PPT_ITEM_SUCCESS = 'PPT_ITEM_SUCCESS';
export const RESOURCE_ITEM_FAILED = 'RESOURCE_ITEM_FAILED';

export const DOC_ITEM_DETAIL = 'DOC_ITEM_DETAIL';
export const VIDEO_RESOURCE_DETAIL = 'VIDEO_RESOURCE_DETAIL';
export const IMAGE_RESOURCE_DETAIL = 'IMAGE_RESOURCE_DETAIL';
export const PPT_RESOURCE_DETAIL = 'PPT_RESOURCE_DETAIL';

export const SUBMIT_CUS = 'SUBMIT_CUS';
export const SUBMIT_END_CUS = 'SUBMIT_END_CUS';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_PAGE_FAILED = 'FETCH_PAGE_FAILED';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED';
export const JOIN_WEBINAR = 'JOIN_WEBINAR';

export const FETCH_FAQ = 'FETCH_FAQ';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAILED = 'FETCH_FAQ_FAILED';

export const FETCH_PLAN_FEATURE = 'FETCH_PLAN_FEATURE';

export const FETCH_APPCONTENT = 'FETCH_APPCONTENT';
export const FETCH_APPCONTENT_SUCCESS = 'FETCH_APPCONTENT_SUCCESS';
export const FETCH_APPCONTENT_FAILED = 'FETCH_APPCONTENT_FAILED';

export const SAMPLE_COURSE = 'SAMPLE_COURSE';

export const MY_EVENTS = 'MY_EVENTS';
export const MY_EVENTS_SUCCESS = 'MY_EVENTS_SUCCESS';
export const MY_EVENTS_FAILED = 'MY_EVENTS_FAILED';

export const UPDATE_PLAN_STATUS = 'UPDATE_PLAN_STATUS';
export const RENEW_PLAN = 'RENEW_PLAN';
export const CANCEL_PLAN = 'CANCEL_PLAN';

export const INITIATE_REFUND = 'INITIATE_REFUND'
export const FETCH_CITIES = 'FETCH_CITIES'


export const FETCH_EVALUATION = 'FETCH_EVALUATION'
export const SUBMIT_EVALUATION = 'SUBMIT_EVALUATION'
export const FETCH_EVALUATION_SUCCESS = 'FETCH_EVALUATION_SUCCESS'

export const FREE_MEMBERSHIP = 'FREE_MEMBERSHIP'
export const EVENT_SUBSCRIPTION = 'EVENT_SUBSCRIPTION'




