import {toast} from 'react-toastify';
import {MESSAGES} from "../constants/Messages";
import moment from 'moment';
import {DEFAULT_CURRENCY} from "../constants/Config";

export const isLoggedIn = () => {
    const token = localStorage.getItem('token');
    if (token && token !== null && token !== '') {
        return true;
    }
    return false;
}

export const isTempLoggedIn = () => {
    const tempData = localStorage.getItem('tempuserInfo');
    if (tempData && tempData !== null && tempData !== '') {
        return true;
    }
    return false;
}

export const loggedInUserData = () => {
    const userData = localStorage.getItem('userInfo');
    if (userData && userData !== null && userData !== '') {
        return JSON.parse(userData);
    }
    return '';
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const setMinLength = (val) => {
    return { value: val, message: `Please enter at-least ${val} character` }
}

export const setMaxLength = (val) => {
    return { value: val, message: `Please enter maximum ${val} character` }
}

export const errorMessage = (error, message = MESSAGES.DefaultError) => {
    if(error.response && error.response.status && error.response.status === 401 || error.response.status === 322) {
        toast.error(error.response.data.message);
        localStorage.clear();
        setTimeout(()=>{
            window.location.reload();
        }, 2000);
    } else if(error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
    } else {
        toast.error(message);
    }

}

export const getMonths = () => {
    return [
        {value: "01", name: "January"}, {value: "02", name: "February"}, {value: "03", name: "March"}, {value: "04", name: "April"},
        {value: "05", name: "May"}, {value: "06", name: "June"},
        {value: "07", name: "July"}, {value: "08", name: "August"}, {value: "09", name: "September"}, {value: "10", name: "October"}, {value: "11", name: "November"},
        {value: "12", name: "December"} ]
}

export const getYears = () => {
    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(10, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'));
        dateStart.add(1, 'year')
    }
    return years
}

export const getYear = () => {
    var currentYear = new Date().getFullYear()
    var years = [];
    var startYear = 2000;
    for(var i=startYear; i<= currentYear; i++){
       years.push(startYear++);
    }
    return years.reverse();
 }

export const getLastFiveYears = () => {
    const years = [];
    const dateStart = moment().subtract(5, 'y');
    const dateEnd = moment();
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'));
        dateStart.add(1, 'year')
    }
    let curryear=new Date().getFullYear()+1;
    const index = years.indexOf(curryear);
    years.splice(index, 1);

    return years.reverse();
}

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const displayAmount = (amt) => {
    return `${DEFAULT_CURRENCY}${amt && amt != ''?amt:0}`;
}

export const removeValue = function(list, value, separator) {
    separator = separator || ", ";
    let values = list.split(separator);
    for(var i = 0 ; i < values.length ; i++) {
        if(values[i] == value) {
            values.splice(i, 1);
            return values.join(separator);
        }
    }
    return list;
}


export const isInThePast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}

export const subSTR = (text,limit) => {
    if(text){
        return text.substring(0, limit) + "..."
    }else {
        return ''
    }
}