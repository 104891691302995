import {
    FETCH_EVENTS_FAILED,
    FETCH_EVENTS_SUCCESS,
    MY_EVENTS_FAILED,
    MY_EVENTS_SUCCESS
} from "../constants/ActionTypes";

const initState = {
    eventlist: [],
    myeventlist:[]
}
export default function course(state = initState, action) {
    switch (action.type) {
        case FETCH_EVENTS_SUCCESS:
            return {...state, eventlist: action.eventlist};
        case FETCH_EVENTS_FAILED:
            return {...state, eventlist: []};
            case MY_EVENTS_SUCCESS:
            return {...state, myeventlist: action.myeventlist};
        case MY_EVENTS_FAILED:
            return {...state, myeventlist: []};
     
        default:
            return state
    }
}