import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {myCEUsAPI, submitCEUsAPI,affiliationAPI,updateCourseAPI,deleteCourseAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    FETCH_MY_CEUS,
    HIDE_LOADING,
    MY_CEUS_FAILED,
    MY_CEUS_SUCCESS,
    SHOW_LOADING,
    SUBMIT_CEUS,
    FETCH_AFFILIATION,
    FETCH_AFFILIATION_SUCCESS,
    UPDATE_CUES,
    DELETE_CUES
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";


function* fetchMyCEUS(action) {

    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(myCEUsAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: MY_CEUS_SUCCESS, CEUsData: response.data.data});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: MY_CEUS_FAILED});
    }
}

function* submitOtherCourse(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(submitCEUsAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* updateCourse(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(updateCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* deleteCourse(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(deleteCourseAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        action.onSuccess(false);
    }
}

function* fetchAffiliation(action) {
    try {
        const response = yield call(affiliationAPI, action.payload);
        yield put({type: FETCH_AFFILIATION_SUCCESS, affiliationData: response.data.data});
    } catch (error) {
        errorMessage(error);
    }
}

function* actionMyCEUs() {
    yield takeLatest(FETCH_MY_CEUS, fetchMyCEUS)
}

function* actionSubmitCEUs() {
    yield takeLatest(SUBMIT_CEUS, submitOtherCourse)
}

function* actionupdateCEUs() {
    yield takeLatest(UPDATE_CUES, updateCourse)
}

function* actionFetchAffiliation() {
    yield takeLatest(FETCH_AFFILIATION, fetchAffiliation)
}

function* actionDeleteCourse() {
    yield takeLatest(DELETE_CUES, deleteCourse)
}

export default function* authSaga() {
    yield all([
        fork(actionMyCEUs),
        fork(actionSubmitCEUs),
        fork(actionFetchAffiliation),
        fork(actionupdateCEUs),
        fork(actionDeleteCourse)
    ]);
}
