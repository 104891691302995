import {all, call, put, takeLatest, delay, fork } from 'redux-saga/effects';
import {
    buyMembershipAPI,freeMembershipAPI,
    changePasswordAPI, checkPromoCode, forgotPasswordAPI, masterDataAPI, plansAPI, resetPasswordAPI, stateMasterAPI,cityMasterAPI,
    timeZoneAPI,
    userList, userLogin,
    userLogout, userProfile, userRegister, userUpdateAPI,planFeatureAPI,appContentAPI,
    onResendEmailAPI,onSampleCourselAPI,onValidateResetAPI,onUpdatePlanAPI,onRenewPlanAPI,onCancelPlanAPI,initiateRefundAPI
} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    CHANGE_PASSWORD, CHECK_PROMOCODE, COURSE_WATCH_STATUS, FETCH_MASTERS, FETCH_MASTERS_FAILED, FETCH_MASTERS_SUCCESS,
    FETCH_PLANS,
    FETCH_PLANS_FAILED,
    FETCH_PLANS_SUCCESS,
    FETCH_USERS, FORGOT, HIDE_LOADING, LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT, LOGOUT_SUCCESS, MEMBERSHIP, REGISTER,
    RESET_PASSWORD,
    SHOW_LOADING, UPDATE_USER_PROFILE, USER_PROFILE, USER_PROFILE_FAILED, USER_PROFILE_SUCCESS, 
    FETCH_PLAN_FEATURE,FETCH_APPCONTENT ,FETCH_APPCONTENT_SUCCESS,FETCH_APPCONTENT_FAILED,
    RESEND_PASSWORD_LINK,SAMPLE_COURSE,
    VALIDATE_RESET_LINK,UPDATE_PLAN_STATUS,RENEW_PLAN,CANCEL_PLAN,FETCH_CITIES,
    INITIATE_REFUND, FREE_MEMBERSHIP
} from "../constants/ActionTypes";
import {errorMessage} from "../helpers/utils";

function* loginUser(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(userLogin, action.payload);
        if(userData.data.data.user.is_paid_member) {
            toast.success(userData.data.message);
        } 
        // else {
        //     toast.success('You are not a Premier User, please buy a membership to continue.');
        // }
        yield put({type: HIDE_LOADING});
        yield put({type: LOGIN_SUCCESS, userData: userData.data.data});
        action.onSuccess(userData.data.data);
    } catch (error) {
        errorMessage(error);
        action.onSuccess(false);
        yield put({type: HIDE_LOADING});
        yield put({type: LOGIN_FAILED});
    }
}

function* logoutUser(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(userLogout);
        toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        yield put({type: LOGOUT_SUCCESS});
        action.onSuccess();
    } catch (error) {
        action.onFailed();
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* registerUser(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(userRegister, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* updateUser(action) {
    try {

        yield put({type: SHOW_LOADING});
        const response = yield call(userUpdateAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* forgotPassword(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(forgotPasswordAPI, action.payload);
        toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* onResendpasswordLink(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(onResendEmailAPI, action.payload);
        toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* changePassword(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(changePasswordAPI, action.payload);
        toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}


function* resetPassword(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(resetPasswordAPI, action.payload);
        toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* validateResetLink(action) {
    try {
        yield put({type: SHOW_LOADING});
        const userData = yield call(onValidateResetAPI, action.payload);
        // toast.success(userData.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccessValidate(userData.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* fetchMasters() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(masterDataAPI);
        const stateResponse =  yield call(stateMasterAPI);
        const timeZoneResponse =  yield call(timeZoneAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_MASTERS_SUCCESS, 
            masterData: response.data.data, 
            stateData: stateResponse.data.data, 
            timeZoneData: timeZoneResponse.data.data,
        });
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_MASTERS_FAILED});
    }
}

function* fetchCities(action) {
    try {
        // yield put({type: SHOW_LOADING});
        const cityResponse =  yield call(cityMasterAPI, action.payload);
        const data = cityResponse.data.data && cityResponse.data.data.data
        action.onSuccess(data);
        // yield put({type: HIDE_LOADING});
    } catch (error) {
        errorMessage(error);
        // yield put({type: HIDE_LOADING});
    }
}



function* fetchPlans() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(plansAPI);
        const stateResponse =  yield call(stateMasterAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_PLANS_SUCCESS, planData: response.data.data, stateData: stateResponse.data.data});
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_PLANS_FAILED});
    }
}

function* fetchUsers() {
    try {
        yield put({type: SHOW_LOADING});
        yield call(userList);
        toast.success('Login success');
        yield put({type: HIDE_LOADING});
    } catch (e) {
        toast.error('Login failed');
        yield put({type: HIDE_LOADING});
    }
}

function* checkPromo(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(checkPromoCode, action.payload);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    }
}

function* buyMembership(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(buyMembershipAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* freeMembership(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(freeMembershipAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* planFeature(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(planFeatureAPI, action.payload);
        // toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* appContent(action) {
    // try {
    //     yield put({type: SHOW_LOADING});
    //     const response = yield call(appContentAPI, action.payload);
    //     // toast.success(response.data.message);
    //     yield put({type: HIDE_LOADING});
    //     action.onSuccess(response.data.data);
    // } catch (error) {
    //     errorMessage(error);
    //     yield put({type: HIDE_LOADING});
    // }

    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(appContentAPI, action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_APPCONTENT_SUCCESS, appContentData: response.data.data });
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: FETCH_APPCONTENT_FAILED});
    }
}


function* fetchUserProfile() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(userProfile);
        console.log('response',response)
        if(response){
            yield put({type: USER_PROFILE_SUCCESS, userProfile: response.data.data});
            yield put({type: HIDE_LOADING});
        }
    } catch (error) {
        errorMessage(error);
        yield put({type: USER_PROFILE_FAILED});
        yield put({type: HIDE_LOADING});
    }
}
function* sampleCourse(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(onSampleCourselAPI, action.payload);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* cancelPlanStatus(action) {
    try {

        yield put({type: SHOW_LOADING});
        const response = yield call(onCancelPlanAPI);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* renewPlan(action) {
    try {

        yield put({type: SHOW_LOADING});
        const response = yield call(onRenewPlanAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
        action.onSuccess(response.data.data);
    } catch (error) {
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}

function* initiateRefund(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response = yield call(initiateRefundAPI, action.payload);
        toast.success(response.data.message);
        yield put({type: HIDE_LOADING});
    } catch (error) {
        yield put({type: HIDE_LOADING});
        errorMessage(error);
    }
}


function* actionSampleCourse() {
    yield takeLatest(SAMPLE_COURSE, sampleCourse)
}
function* actionLogin() {
    yield takeLatest(LOGIN, loginUser)
}

function* actionLogout() {
    yield takeLatest(LOGOUT, logoutUser)
}

function* actionRegister() {
    yield takeLatest(REGISTER, registerUser)
}

function* actionUpdateProfile() {
    yield takeLatest(UPDATE_USER_PROFILE, updateUser)
}

function* actionForgot() {
    yield takeLatest(FORGOT, forgotPassword)
}

function* actionChangePassword() {
    yield takeLatest(CHANGE_PASSWORD, changePassword)
}

function* actionUserListing() {
    yield takeLatest(FETCH_USERS, fetchUsers)
}

function* actionFetchMaster() {
    yield takeLatest(FETCH_MASTERS, fetchMasters)
}

function* actionFetchCities() {
    yield takeLatest(FETCH_CITIES, fetchCities)
}

function* actionFetchPlans() {
    yield takeLatest(FETCH_PLANS, fetchPlans)
}

function* actionAppcontent() {
    yield takeLatest(FETCH_APPCONTENT, appContent)
}
function* actionCheckPromoCode() {
    yield takeLatest(CHECK_PROMOCODE, checkPromo)
}

function* actionProfile() {
    yield takeLatest(USER_PROFILE, fetchUserProfile)
}

function* actionMembership() {
    yield takeLatest(MEMBERSHIP, buyMembership)
}

function* actionFreeMembership() {
    yield takeLatest(FREE_MEMBERSHIP, freeMembership)
}


function* actionResetPassword() {
    yield takeLatest(RESET_PASSWORD, resetPassword)
}
function* actionValidateResetLink() {
    yield takeLatest(VALIDATE_RESET_LINK, validateResetLink)
}

function* actionPlanFeature() {
    yield takeLatest(FETCH_PLAN_FEATURE, planFeature)
}

function* actionResendpasswordLink() {
    yield takeLatest(RESEND_PASSWORD_LINK, onResendpasswordLink)
}
function* actionCancelPlanStatus() {
    yield takeLatest(CANCEL_PLAN, cancelPlanStatus)
}
function* actionRenewPlan() {
    yield takeLatest(RENEW_PLAN, renewPlan)
}

function* actionInitiateRefund() {
    yield takeLatest(INITIATE_REFUND, initiateRefund)
}


export default function* authSaga() {
    yield all([
        fork(actionLogin),
        fork(actionProfile),
        fork(actionLogout),
        fork(actionForgot),
        fork(actionFetchMaster),
        fork(actionChangePassword),
        fork(actionUserListing),
        fork(actionRegister),
        fork(actionFetchPlans),
        fork(actionCheckPromoCode),
        fork(actionMembership),
        fork(actionFreeMembership),
        fork(actionUpdateProfile),
        fork(actionResetPassword),
        fork(actionPlanFeature),
        fork(actionAppcontent),
        fork(actionResendpasswordLink),
        fork(actionSampleCourse),
        fork(actionValidateResetLink),
        fork(actionRenewPlan),
        fork(actionCancelPlanStatus),
        fork(actionInitiateRefund),
        fork(actionFetchCities)
    ]);
}
