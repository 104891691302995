import {MY_CEUS_FAILED, MY_CEUS_SUCCESS,FETCH_AFFILIATION_SUCCESS} from "../constants/ActionTypes";

const initialState = {
    CEUsData: {},
    affiliationData: []
};

export default function ceus(state = initialState, action) {
    switch (action.type) {
        case MY_CEUS_SUCCESS:
            return {...state, CEUsData: action.CEUsData }
        case MY_CEUS_FAILED:
            return {...state, CEUsData: {} }
        case FETCH_AFFILIATION_SUCCESS:
            return {...state, affiliationData: action.affiliationData }
        default:
            return state
    }
}