//export const BASE_URL = 'http://lms.passy-muir.com/api/v1';
//export const BASE_URL = 'https://medicorumslpapi.siplsolutions.com/api/v1';
export const BASE_URL = 'https://ceapp.passymuir.com/api/v1';
export const DEFAULT_CURRENCY = '$';
export const API = {
    login: 'login',
    register: 'register',
    userProfile: 'user-profile',
    logout: 'logout',
    changePassword: 'change-password',
    resetPassword: 'reset-password',
    forgotPassword: 'forgot-password',
    editProfile: 'edit-profile',
    masters: 'masters',
    facilities: 'facilities',
    affiliations: 'affiliations',
    keywords: 'keywords',
    membership: 'membership',
    checkPromoCode: 'check-promocode',
    states: 'states',
    city: 'us-cities',
    billing: 'billing',
    free_membership: 'free-membership-billing',
    courses: 'courses',
    categories: 'categories',
    courseDetail: 'course-detail',
    enrollCourse: 'enroll-course',
    unEnrollCourse: 'unenroll-course',
    bookmarkCourse: 'bookmark-course',
    unBookmarkCourse: 'unbookmark-course',
    courseWatchStatus: 'course-watch-status',
    courseQuiz: 'course-quiz',
    quizSubmit: 'attempt-quiz',
    getEvaluation: 'evaluation',
    evaluationSubmit: 'evaluation-submission',
    myCourses: 'mycourses',
    myCEU: 'my-ceus',
    addOtherCourse: 'add-other-course',
    editOtherCourse: 'edit-other-course',
    removeOtherCourse: 'remove-other-course',
    addCourseRating: 'add-course-rating',
    courseRating: 'course-rating',
    users: 'users',
    lendingItems: 'lending-items',
    addLendingShippingDetails: 'add-shipping-details',
    editLendingShippingDetails: 'edit-shipping-details',
    shippingDetails: 'shipping-details',
    paymentInformation: 'get-payment-customer-details',
    placeOrderLending: 'place-order',
    myLendingOrder: 'my-lending-orders',
    editBillingInfo: 'update-card-details',
    editShippinMethod: 'shipping-methods',
    notification: 'notification',
    readNotification: 'read-notification',
    fetchReferral: 'referral-code',
    fetchBonus: 'get-bonus',
    resourceItems: 'resource-items',
    contactUs: 'contact-us',
    contactEndUs: 'end-user-contact-us',
    timezone: 'timezone',
    appContent: 'app-content',
    liveEvents: 'live-events',
    joinEvent: 'join-event',
    eventSubscription: 'event-payment',
    faqs: 'faqs',
    membershipFeatures: 'membership-features',
    appContent: 'app-content',
    emailResend: 'email/resend',
    offeredCourses: 'offered-courses',
    offeredCourseByid: 'offered-course-byid',
    validateReset: 'validate-reset',
    placeOrder: 'place-order',
    allLendingItems: 'all-lending-items',
    allResourceItems: 'all-resource-items',
    userSubscribedEvent: 'user-subscribed-event',
    updateAutoRenew: 'update-auto-renew',
    renewSubscription: 'renew-subscription',
    cancelSubscription:'cancel-subscription',
    affiliation: 'affiliations',
    initiate_refund: 'initiate-refund'

}
